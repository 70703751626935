import {Injectable} from '@angular/core';
import {DonorDetailsExtraParams, NavigationItemBaseService} from '../../declarations';
import {NavigationItemTitleElementToken, NavigationItemUiModel} from '@matchsource/models/navigation-history';
import {translate} from '@jsverse/transloco';
import {formatGrid} from '@matchsource/models/source';

@Injectable()
export class DonorDetailsResolver implements NavigationItemBaseService {
  linkMask = 'donor.donor-details';

  resolve(item: NavigationItemUiModel): NavigationItemUiModel {
    const {grid} = item.extraParams as DonorDetailsExtraParams;
    const formattedGrid = formatGrid(grid);

    item.titleElements = [
      {
        token: NavigationItemTitleElementToken.Link,
        text: translate(`NAV_HISTORY.LINKS.DONOR.DONOR-DETAILS`),
      },
      {
        token: NavigationItemTitleElementToken.NewLine,
      },
      {
        token: NavigationItemTitleElementToken.Title,
        text: translate(`COMMON.COLUMNS.GRID`),
      },
      {
        token: NavigationItemTitleElementToken.Text,
        text: `&nbsp;${formattedGrid}`,
      },
    ];

    return item;
  }
}
