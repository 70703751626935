import {NavigationGroupType} from '@matchsource/models/navigation-history';
import {RejectType, StateObject, Transition} from '@uirouter/angular';
import {hasOwnNestedProperty, isObject, resolve} from '@matchsource/utils';
import {GroupTypeComplexRouterParam} from './declarations';
import {Rejection} from '@uirouter/core';

const GROUP_TYPE_PARAM_KEY = 'data.navigation.groupType';
const OVERRIDE_ROUTE_PARAM_KEY = 'data.navigation.overrideChildRoute';
const COMPARE_PARAMS_PARAM_KEY = 'data.navigation.compareParams';
const EXTRA_PARAMS_PARAM_KEY = 'data.navigation.getExtraParams';
const SAVE_PARAM_KEY = 'data.navigation.save';

const resolveFnOrReturn = (val: any, ...args: any): any => {
  if (typeof val !== 'function') {
    return val;
  }

  return val(...args);
};
const isGroupTypeComplexRouterParam = (groupType: unknown): groupType is GroupTypeComplexRouterParam => {
  return isObject(groupType);
};
const normalizeGroupTypeRouterParams = (groupTypeParam: unknown): GroupTypeComplexRouterParam => {
  if (isGroupTypeComplexRouterParam(groupTypeParam)) {
    return groupTypeParam;
  }

  return {
    inherited: true,
    groupType: groupTypeParam as NavigationGroupType,
  };
};

const findClosestStateParam = <T = any>(state: StateObject, param: string): T => {
  let currentState = state;

  while (currentState !== null) {
    if (hasOwnNestedProperty(currentState, param)) {
      return resolve(currentState, param);
    }

    currentState = currentState.parent;
  }

  return undefined;
};

const findClosestStateBy = (state: StateObject, predicate: (state: StateObject) => boolean): StateObject => {
  let currentState = state;

  while (currentState !== null) {
    if (predicate(currentState)) {
      return currentState;
    }

    currentState = currentState.parent;
  }

  return undefined;
};

export const getGroupType = (state: StateObject, transition: Transition): NavigationGroupType => {
  if (hasOwnNestedProperty(state, GROUP_TYPE_PARAM_KEY)) {
    const groupType = resolve(state, GROUP_TYPE_PARAM_KEY);

    return resolveFnOrReturn(groupType, transition);
  }

  const parentGroupType = findClosestStateParam(state, GROUP_TYPE_PARAM_KEY);
  if (!parentGroupType) {
    return NavigationGroupType.None;
  }

  const parentGroupParam = normalizeGroupTypeRouterParams(parentGroupType);

  if (parentGroupParam.inherited) {
    return resolveFnOrReturn(parentGroupParam.groupType, transition);
  }

  return NavigationGroupType.None;
};

export const getItemRoute = (state: StateObject): string => {
  const stateWithOverrideRoute = findClosestStateBy(state, (potentialState: StateObject) => {
    return hasOwnNestedProperty(potentialState, OVERRIDE_ROUTE_PARAM_KEY);
  });

  if (stateWithOverrideRoute && resolve(stateWithOverrideRoute, OVERRIDE_ROUTE_PARAM_KEY)) {
    return stateWithOverrideRoute.name;
  }

  return state.name;
};

export const getCompareParams = (state: StateObject): string[] | null => {
  const compareParams = findClosestStateParam(state, COMPARE_PARAMS_PARAM_KEY);

  return compareParams || null;
};

export const getExtraParams = async (
  state: StateObject,
  transition: Transition
): Promise<Record<string, any> | null> => {
  const getExtraParamsFn = findClosestStateParam(state, EXTRA_PARAMS_PARAM_KEY);

  if (!getExtraParamsFn) {
    return null;
  }

  return getExtraParamsFn(transition);
};

export const shouldItemBeSaved = (state: StateObject): boolean => {
  return findClosestStateParam(state, SAVE_PARAM_KEY) || false;
};

export const isSupersededRejection = (ex: unknown) => ex instanceof Rejection && ex.type === RejectType.SUPERSEDED;
