import {NavigationItemModel} from '@matchsource/models/navigation-history';

export class GetNavigationHistory {
  static readonly type = '[NavigationHistory] Get History';
}

export class SaveNavigationItem {
  static readonly type = '[NavigationHistory] Save Navigation Item';
  constructor(public readonly navigationItem: NavigationItemModel) {}
}

export class UpdateNavigationItem {
  static readonly type = '[NavigationHistory] Update Navigation Item';
  constructor(public readonly navigationItem: NavigationItemModel) {}
}
