import {Injectable} from '@angular/core';
import {NavigationItemBaseService} from '../../declarations';
import {LookupResolver} from './lookup-resolver.service';

@Injectable()
export class PatientLookupLinkResolver extends LookupResolver implements NavigationItemBaseService {
  linkMask = 'patients-lookup';

  readonly titleKey = 'NAV_HISTORY.LINKS.PATIENTS-LOOKUP';
}
