import {Injectable, OnDestroy} from '@angular/core';
import {Store} from '@ngxs/store';
import {GetNavigationHistory, SaveNavigationItem, UpdateNavigationItem} from '../store/navigation-history.actions';
import {BehaviorSubject, firstValueFrom, from, Observable, of, Subject} from 'rxjs';
import {NavigationHistoryState} from '../store/navigation-history.state';
import {NavigationGroups, NavigationItemModel} from '@matchsource/models/navigation-history';
import {catchError, distinctUntilChanged, switchMap, takeUntil, tap} from 'rxjs/operators';
import {NavigationHistoryFormatterService} from './navigation-history-formatter.service';
import {NavigationItemOptions} from '../declarations';
import isEqual from 'lodash-es/isEqual';
import {RouterStateService} from 'ngxs-ui-router';
import {NGXLogger} from 'ngx-logger';
import {isSupersededRejection} from '../router-utils';
import {UserService} from '@matchsource/core';

@Injectable({
  providedIn: 'root',
})
export class NavigationHistoryService implements OnDestroy {
  readonly recentNavigationHistory$: Observable<NavigationGroups>;

  private readonly destroy$ = new Subject<void>();

  private lastSavedItem: NavigationItemModel;

  private navFromHistoryId: number | null = null;

  readonly recentHistoryLoading$ = new BehaviorSubject(false);

  get isFeatureEnabled(): boolean {
    return !this.userService.isCommonPractice();
  }

  constructor(
    private readonly store: Store,
    private readonly navigationHistoryFormatterService: NavigationHistoryFormatterService,
    private readonly routerStateService: RouterStateService,
    private readonly userService: UserService,
    private readonly logger: NGXLogger
  ) {
    this.recentNavigationHistory$ = this.store.select(NavigationHistoryState.history).pipe(
      distinctUntilChanged(),
      tap(() => this.recentHistoryLoading$.next(true)),
      switchMap(history => from(this.navigationHistoryFormatterService.group(history))),
      catchError(error => {
        this.logger.error(error);

        return of([]);
      }),
      tap(() => this.recentHistoryLoading$.next(false)),
      takeUntil(this.destroy$)
    );
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();

    this.recentHistoryLoading$.complete();
  }

  async loadHistory(): Promise<void> {
    if (!this.isFeatureEnabled) {
      return;
    }

    await firstValueFrom(this.store.dispatch(new GetNavigationHistory()));
    const history = this.store.selectSnapshot(NavigationHistoryState.history);

    this.lastSavedItem = history.length > 0 ? history[0] : null;
  }

  async saveNavigationItem(
    navigationItemOptions: NavigationItemOptions,
    paramsForComparison: string[] | null
  ): Promise<void> {
    if (!this.isFeatureEnabled) {
      return;
    }

    const navigationItem = this.createNavigationItem(navigationItemOptions);

    if (this.navFromHistoryId) {
      navigationItem.id = this.navFromHistoryId;
    } else if (this.isEqualItem(this.lastSavedItem, navigationItem, paramsForComparison)) {
      navigationItem.id = this.lastSavedItem.id;
    }

    const action = navigationItem.id
      ? new UpdateNavigationItem(navigationItem)
      : new SaveNavigationItem(navigationItem);
    await firstValueFrom(this.store.dispatch(action));

    const lastSavedItem = this.store.selectSnapshot(NavigationHistoryState.lastSavedItem);

    this.navFromHistoryId = null;
    this.lastSavedItem = lastSavedItem;
  }

  async navigate(item: NavigationItemModel): Promise<void> {
    try {
      this.navFromHistoryId = item.id;
      await this.routerStateService.navigateWithCompletion(item.originalRoute, item.routeParams, {reload: true});

      const newRoute = this.routerStateService.routeName;

      if (newRoute !== item.originalRoute && !this.routerStateService.isChildOfParentState(newRoute, item.route)) {
        throw new Error('Navigation history link is not available.');
      }
    } catch (ex) {
      if (isSupersededRejection(ex)) {
        return;
      }

      const updatedItem = {
        ...item,
        disabled: true,
      };

      try {
        await firstValueFrom(this.store.dispatch(new UpdateNavigationItem(updatedItem)));
      } catch (innerEx) {
        /* empty */
      }
    } finally {
      this.navFromHistoryId = null;
    }
  }

  private isEqualItem(
    item1: NavigationItemModel,
    item2: NavigationItemModel,
    paramsForComparison: string[] | null
  ): boolean {
    if (!item1 || !item2) {
      return false;
    }

    if (item1.route !== item2.route) {
      return false;
    }

    const routeParams1 = item1.routeParams;
    const routeParams2 = item2.routeParams;
    if (!paramsForComparison) {
      return isEqual(routeParams1, routeParams2);
    }

    return paramsForComparison.every(param => routeParams1[param] === routeParams2[param]);
  }

  private createNavigationItem(navigationItemOptions: NavigationItemOptions): NavigationItemModel {
    return {
      ...navigationItemOptions,
      id: null,
      createTimestamp: new Date().getTime(),
      disabled: false,
    };
  }
}
