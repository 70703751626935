import {NavigationGroupBaseService, PatientExtraParams} from '../../declarations';
import {
  NavigationGroups,
  NavigationGroupType,
  NavigationItemModel,
  NavigationItemUiModel,
} from '@matchsource/models/navigation-history';
import {Injectable} from '@angular/core';
import {PatientApiService} from '@matchsource/api/patient';
import {toMap, uniq} from '@matchsource/utils';
import {firstValueFrom} from 'rxjs';
import {formatPatientId, PatientLookupModel} from '@matchsource/models/patient';

@Injectable()
export class PatientNavigationGroup implements NavigationGroupBaseService {
  readonly groupType: NavigationGroupType = NavigationGroupType.Patient;

  readonly prefetchDataEnabled = true;

  private patientsMap: MsApp.Dictionary<PatientLookupModel> = {};

  constructor(private readonly patientApi: PatientApiService) {}

  groupBy(item: NavigationItemUiModel): string | number {
    const patientId = item.routeParams.patientId || item.routeParams.id;

    return patientId;
  }

  async group(items: NavigationItemUiModel[]): Promise<NavigationGroups> {
    const [item] = items;
    const patientId = this.groupBy(item);
    const patientsMap = this.patientsMap;
    const isPatientPrefetched = patientId in patientsMap;
    const patient = isPatientPrefetched ? patientsMap[patientId] : (item.extraParams as PatientExtraParams);

    return [
      {
        disabled: this.prefetchDataEnabled && !isPatientPrefetched,
        patient: patient
          ? {
              ...patient,
              rid: formatPatientId(patient.rid),
            }
          : null,
        items,
      },
    ];
  }

  async prefetchData<T extends NavigationItemModel>(items: T[]): Promise<void> {
    const patientIds: MsApp.Guid[] = uniq(
      items.map(({routeParams}) => routeParams.patientId || routeParams.id).filter(patientId => !!patientId)
    );

    try {
      const patients = await firstValueFrom(
        this.patientApi.search({
          size: patientIds.length,
          guids: patientIds,
        })
      );
      this.patientsMap = toMap(patients.data, 'id');
    } catch (ex) {
      this.patientsMap = {};
    }
  }
}
