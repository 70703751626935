import {Injectable} from '@angular/core';
import {NavigationItemBaseService} from '../../declarations';
import {SearchResultsBaseResolver} from './search-results-base-resolver.service';

@Injectable()
export class AbOnlyDonorSearchResultsLinkResolver
  extends SearchResultsBaseResolver
  implements NavigationItemBaseService
{
  linkMask = 'patient-search.results.abonly';
}
