import {NavigationItemUiModel} from '@matchsource/models/navigation-history';
import {translate} from '@jsverse/transloco';

const ALLELE_MODE = 'allele';

export abstract class SearchResultsBaseResolver {
  resolve(item: NavigationItemUiModel): NavigationItemUiModel {
    const {routeParams} = item;
    const searchResultsMode = routeParams?.mode;

    if (searchResultsMode === ALLELE_MODE) {
      const originalText = item.titleElements[0].text;
      item.titleElements = [
        {
          ...item.titleElements[0],
          text: `${originalText} (${translate('NAV_HISTORY.COMMON.ALLELE_REVEAL')})`,
        },
      ];
    }

    return item;
  }
}
