import {
  NavigationGroups,
  NavigationGroupType,
  NavigationItemModel,
  NavigationItemUiModel,
} from '@matchsource/models/navigation-history';
import {InjectionToken} from '@angular/core';

export const HISTORY_RECORDS_LIMIT = 10;

export const NAVIGATION_GROUP_HANDLERS = new InjectionToken<NavigationGroupBaseService>('NavigationGroups');

export const NAVIGATION_ITEM_HANDLERS = new InjectionToken<NavigationItemBaseService>('NavigationItems');

export interface NavigationGroupBaseService {
  readonly groupType: NavigationGroupType;

  readonly prefetchDataEnabled?: boolean;

  group(items: NavigationItemUiModel[]): Promise<NavigationGroups>;

  groupBy?(item: NavigationItemModel): string | number;

  prefetchData?<T extends NavigationItemModel>(items: T[]): Promise<void>;
}

export interface NavigationItemBaseService {
  readonly linkMask: string;

  resolve(item: NavigationItemUiModel): NavigationItemUiModel;
}

export interface GroupTypeComplexRouterParam {
  inherited: boolean;
  groupType: NavigationGroupType;
}

export interface NavigationItemOptions {
  route: string;
  originalRoute: string;
  routeParams: Record<string, any>;

  extraParams: Record<string, any> | null;
  groupType: NavigationGroupType;
  itemType: string | null;
}

export interface OrderFulfillmentExtraParams {
  orderType: string;
  isSubsequent?: boolean;
}

export interface PatientExtraParams {
  firstName: string;
  lastName: string;
  rid: string;
}

export interface CordDetailsExtraParams {
  grid: string;
}

export interface DonorDetailsExtraParams {
  grid: string;
}

export interface BiobankDetailsExtraParams {
  localId: string;
}

export interface StandardOplExtraParams {
  oplName: string;
}

export interface SsaOplExtraParams {
  oplName: string;
}
