import {NavigationItemTitleElementToken, NavigationItemUiModel} from '@matchsource/models/navigation-history';
import {translate} from '@jsverse/transloco';

export abstract class LookupResolver {
  abstract readonly titleKey: string;

  resolve(item: NavigationItemUiModel): NavigationItemUiModel {
    const {routeParams} = item;
    const searchTerm = routeParams?.search?.trim() || '';

    if (searchTerm.length > 0) {
      item.titleElements = [
        {
          token: NavigationItemTitleElementToken.Link,
          text: translate(this.titleKey),
          postAdditionalText: translate('NAV_HISTORY.LINKS.LOOKUP_SEARCH_TERM_TEXT', {searchTerm}),
        },
      ];
    }

    return item;
  }
}
