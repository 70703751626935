import {ClassProvider, NgModule} from '@angular/core';
import {NgxsModule} from '@ngxs/store';
import {NavigationHistoryState} from './store/navigation-history.state';
import {NAVIGATION_GROUP_HANDLERS, NAVIGATION_ITEM_HANDLERS} from './declarations';
import {PatientNavigationGroup} from './services/groups/patient-navigation-group.service';
import {UnknownNavigationGroup} from './services/groups/unknown-navigation-group.service';
import {DonorSourceNavigationGroup} from './services/groups/donor-source-navigation-group.service';
import {CordSourceNavigationGroup} from './services/groups/cord-source-navigation-group.service';
import {PatientLookupLinkResolver} from './services/link-resolvers/patient-lookup-resolver.service';
import {DonorLookupLinkResolver} from './services/link-resolvers/donor-lookup-resolver.service';
import {CordLookupLinkResolver} from './services/link-resolvers/cord-lookup-resolver.service';
import {BiobankLookupLinkResolver} from './services/link-resolvers/adcp-lookup-resolver.service';
import {BioBankDetailsNavigationGroupService} from './services/groups/bio-bank-details-navigation-group.service';
import {OrderFulfillmentResolver} from './services/link-resolvers/order-fulfillment-resolver.service';
import {StandardOplResolver} from './services/link-resolvers/standard-opl-resolver.service';
import {SSAOplResolver} from './services/link-resolvers/ssa-opl-resolver.service';
import {CordDetailsResolver} from './services/link-resolvers/cord-details-resolver.service';
import {DonorDetailsResolver} from './services/link-resolvers/donor-details-resolver.service';
import {BiobankDetailsResolver} from './services/link-resolvers/biobank-details-resolver.service';
import {DonorSearchResultsLinkResolver} from './services/link-resolvers/donor-search-results-resolver.service';
import {CordSearchResultsLinkResolver} from './services/link-resolvers/cord-search-results-resolver.service';
import {AbOnlyDonorSearchResultsLinkResolver} from './services/link-resolvers/abonly-search-results-resolver.service';

const GROUP_HANDLERS_PROVIDERS: Array<ClassProvider & {provide: typeof NAVIGATION_GROUP_HANDLERS; multi: true}> = [
  {
    provide: NAVIGATION_GROUP_HANDLERS,
    useClass: PatientNavigationGroup,
    multi: true,
  },
  {
    provide: NAVIGATION_GROUP_HANDLERS,
    useClass: DonorSourceNavigationGroup,
    multi: true,
  },
  {
    provide: NAVIGATION_GROUP_HANDLERS,
    useClass: CordSourceNavigationGroup,
    multi: true,
  },
  {
    provide: NAVIGATION_GROUP_HANDLERS,
    useClass: UnknownNavigationGroup,
    multi: true,
  },
  {
    provide: NAVIGATION_GROUP_HANDLERS,
    useClass: BioBankDetailsNavigationGroupService,
    multi: true,
  },
];

const ITEM_HANDLERS_PROVIDERS: Array<ClassProvider & {provide: typeof NAVIGATION_ITEM_HANDLERS; multi: true}> = [
  {
    provide: NAVIGATION_ITEM_HANDLERS,
    useClass: PatientLookupLinkResolver,
    multi: true,
  },
  {
    provide: NAVIGATION_ITEM_HANDLERS,
    useClass: DonorLookupLinkResolver,
    multi: true,
  },
  {
    provide: NAVIGATION_ITEM_HANDLERS,
    useClass: CordLookupLinkResolver,
    multi: true,
  },
  {
    provide: NAVIGATION_ITEM_HANDLERS,
    useClass: BiobankLookupLinkResolver,
    multi: true,
  },
  {
    provide: NAVIGATION_ITEM_HANDLERS,
    useClass: OrderFulfillmentResolver,
    multi: true,
  },
  {
    provide: NAVIGATION_ITEM_HANDLERS,
    useClass: StandardOplResolver,
    multi: true,
  },
  {
    provide: NAVIGATION_ITEM_HANDLERS,
    useClass: SSAOplResolver,
    multi: true,
  },
  {
    provide: NAVIGATION_ITEM_HANDLERS,
    useClass: CordDetailsResolver,
    multi: true,
  },
  {
    provide: NAVIGATION_ITEM_HANDLERS,
    useClass: DonorDetailsResolver,
    multi: true,
  },
  {
    provide: NAVIGATION_ITEM_HANDLERS,
    useClass: BiobankDetailsResolver,
    multi: true,
  },
  {
    provide: NAVIGATION_ITEM_HANDLERS,
    useClass: DonorSearchResultsLinkResolver,
    multi: true,
  },
  {
    provide: NAVIGATION_ITEM_HANDLERS,
    useClass: CordSearchResultsLinkResolver,
    multi: true,
  },
  {
    provide: NAVIGATION_ITEM_HANDLERS,
    useClass: AbOnlyDonorSearchResultsLinkResolver,
    multi: true,
  },
];

@NgModule({
  imports: [NgxsModule.forFeature([NavigationHistoryState])],
  providers: [...GROUP_HANDLERS_PROVIDERS, ...ITEM_HANDLERS_PROVIDERS],
})
export class NavigationHistoryModule {}
