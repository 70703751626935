import {Injectable} from '@angular/core';
import {NavigationItemBaseService} from '../../declarations';
import {LookupResolver} from './lookup-resolver.service';

@Injectable()
export class DonorLookupLinkResolver extends LookupResolver implements NavigationItemBaseService {
  linkMask = 'donors-lookup';

  readonly titleKey = 'NAV_HISTORY.LINKS.DONORS-LOOKUP';
}
