import {NavigationItemTitleElementToken, NavigationItemUiModel} from '@matchsource/models/navigation-history';
import {translate} from '@jsverse/transloco';
import {NavigationItemBaseService, StandardOplExtraParams} from '../../declarations';

export class StandardOplResolver implements NavigationItemBaseService {
  linkMask = 'patient-search.opl.opl-standard';

  resolve(item: NavigationItemUiModel): NavigationItemUiModel {
    const extraParams = item.extraParams as StandardOplExtraParams;
    const oplName = extraParams.oplName;

    item.titleElements = [
      {
        token: NavigationItemTitleElementToken.Link,
        text: translate(`NAV_HISTORY.LINKS.PATIENT-SEARCH.OPL.OPL-STANDARD`),
      },
      {
        token: NavigationItemTitleElementToken.Text,
        text: translate(`NAV_HISTORY.LINKS.OPL_NAME_TEXT`, {oplName}),
      },
    ];

    return item;
  }
}
