import {MapTo} from '@matchsource/api-utils';
import {NavigationGroupType, NavigationItemModel} from '@matchsource/models/navigation-history';
import {RecentHistoryPage} from '@matchsource/api-generated/common';
import {toDateTime, toTimestamp} from '@matchsource/date';

export const mapNavigationItemModelToDto: MapTo<NavigationItemModel, RecentHistoryPage> = (
  model: NavigationItemModel
) => ({
  extraParameters: JSON.stringify(model.extraParams),
  groupType: model.groupType,
  openTimestamp: toDateTime(model.createTimestamp),
  id: model.id,
  parentRoute: model.route,
  route: model.originalRoute,
  routeParameters: JSON.stringify(model.routeParams),
  pageDisabled: model.disabled || false,
});

export const mapDtoToNavigationItemModel: MapTo<RecentHistoryPage, NavigationItemModel> = (dto: RecentHistoryPage) => ({
  id: dto.id,
  createTimestamp: toTimestamp(dto.openTimestamp),
  disabled: dto.pageDisabled,
  route: dto.parentRoute,
  originalRoute: dto.route,
  routeParams: JSON.parse(dto.routeParameters),
  extraParams: JSON.parse(dto.extraParameters) || {},
  groupType: (dto.groupType || NavigationGroupType.None) as NavigationGroupType,
  itemType: null,
});
