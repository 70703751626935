import {Injectable} from '@angular/core';
import {CordDetailsExtraParams, NavigationItemBaseService} from '../../declarations';
import {NavigationItemTitleElementToken, NavigationItemUiModel} from '@matchsource/models/navigation-history';
import {translate} from '@jsverse/transloco';
import {formatGrid} from '@matchsource/models/source';

@Injectable()
export class CordDetailsResolver implements NavigationItemBaseService {
  linkMask = 'cord.cord-details';

  resolve(item: NavigationItemUiModel): NavigationItemUiModel {
    const {grid} = item.extraParams as CordDetailsExtraParams;
    const formattedGrid = formatGrid(grid);

    item.titleElements = [
      {
        token: NavigationItemTitleElementToken.NewLine,
      },
      {
        token: NavigationItemTitleElementToken.Link,
        text: translate(`NAV_HISTORY.LINKS.CORD.CORD-DETAILS`),
      },
      {
        token: NavigationItemTitleElementToken.NewLine,
      },
      {
        token: NavigationItemTitleElementToken.Title,
        text: translate(`COMMON.CORD_ID`),
      },
      {
        token: NavigationItemTitleElementToken.Text,
        text: `&nbsp;${formattedGrid}`,
      },
    ];

    return item;
  }
}
