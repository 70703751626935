import {Injectable} from '@angular/core';
import {NavigationItemBaseService} from '../../declarations';
import {LookupResolver} from './lookup-resolver.service';

@Injectable()
export class BiobankLookupLinkResolver extends LookupResolver implements NavigationItemBaseService {
  linkMask = 'biobanks-lookup';

  readonly titleKey = 'NAV_HISTORY.LINKS.BIOBANKS-LOOKUP';
}
